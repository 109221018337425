import React, { useState, useEffect }  from 'react';
import { Form, Layout, Upload, message, Button, notification } from 'antd';
import { Table } from "ant-table-extensions";
import { UploadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment-timezone';

const { Content } = Layout;

const AppFileUpload = () => {
  const [form] = Form.useForm();
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(true);

  const openNotification = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
      placement: "topRight"
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const props = {
    action: `/upload`, 
    accept: ".csv",
    multiple: false,
    beforeUpload(file) {
      openNotification("info", "Yükleme Başladı!", "Lütfen bekleyiniz.");
      if (file.size > 10485760) {
        openNotification("error", "Yükleme Başarısız!", "Dosya boyutu maksimum 10 MB olmalıdır.");
        return message.error(`${file.name} File size must be less than 10 MB`);
      }
    },
    onChange(info) {
      if (info.file.status === 'done') {
        openNotification("success", "Yükleme Tamamlandı!", "Veriyi başarıyla yüklediniz.");
      } else if (info.file.status === 'error') {
        openNotification("error", "Yükleme Başarısız!", "Bir hata meydana geldi. Lütfen tekrar deneyiniz.");
      }
    }
  };

  const getData = async () => {
    await axios.post(`/upload-data-list`).then(
      res => {
        setloading(false);
        setstate(
          res.data.data.map(row => ({
            key: moment(row.CREATED_DATE),
            Date: moment(row.CREATED_DATE).add(-3, 'hours').format("DD/MM/YYYY HH:mm"),
            TotalContact: row.TOTAL_CONTACT,
            SuccessContact: row.SUCCESS_CONTACT,
            FailContact: row.UNSUCCESS_CONTACT,
            SuccessIys: row.SUCCESS_IYS,
            FailIys: row.UNSUCCESS_IYS,
            UncheckedIys: row.UNCHECK_IYS,
          }))
        );
      }
    );
  };

  const columns = [
    {
      title: "Tarih",
      dataIndex: "Date"
    },
    {
      title: "Toplam Veri",
      dataIndex: "TotalContact"
    },
    {
      title: "Başarılı Veri",
      dataIndex: "SuccessContact"
    },
    {
      title: "Başarısız Veri",
      dataIndex: "FailContact"
    },
    {
      title: "IYS İzinli Veri",
      dataIndex: "SuccessIys"
    },
    {
      title: "IYS İzinsiz Veri",
      dataIndex: "FailIys"
    },
    {
      title: "IYS Kontrol Edilmemiş Veri",
      dataIndex: "UncheckedIys"
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'number',
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });
  return (
    <Content>
      <div className="child-header" align="center">
        <Upload {...props} >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </div>
      <div>
        <Form form={form} component={false}>
          <Table
            bordered
            dataSource={state}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              
            }}
            searchable
            searchableProps={{
              inputProps: {
                placeholder: "Search this table...",
                prefix: <SearchOutlined />,
              },
            }}
            exportable
            exportableProps={{ 
              showColumnPicker: true, 
              fileName: "Dosya-Yukleme" ,
              btnProps: {
                type: "success",
                icon: <FileExcelOutlined />,
                children: <span>Export to CSV</span>
              }
            }}
          />
        </Form>
      </div>  
    </Content> 
  );
};

export default AppFileUpload;