import React, {useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import axios from 'axios';
import { serverUrl } from './config';

import { Layout, Spin } from 'antd';
import './App.css';
import 'antd/dist/antd.css';

import AppHeader from './components/common/header';
import AppLogin from './components/login/login';
import AppHome from './views/home';
import AppFooter from './components/common/footer';

const { Header, Content, Footer } = Layout;

const App = (props) => {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [cookies, setCookie] = useCookies(['session']);

  useEffect(() => {
    let session = cookies.session;

    if (!session ){
      setIsAuth(false);
      setIsLoadingPage(false);
      return;
    }
    axios({
      url: serverUrl,
      method: "GET",
      params: {
        function: "checklogin",
        session: session
      }
    })
    .then(response => {
      if(!response.data.login) throw new Error("Session wrong or expired");
      setIsLoadingPage(false);
      setIsAuth(true);
    })
    .catch(error => {
      const { response } = error;
      if (response) {
        const { request, ...errorObject } = response; // take everything but 'request'
        console.log(errorObject);
        //return openNotification("warning", "Login Error", errorObject.data.message);
      }
      else {
        //return openNotification("error", "Login Error", error.message);
      }
      setIsLoadingPage(false);
      setIsAuth(false);
    })
  }, []);

  return (
    <Layout className="mainLayout">
      <Header>
        <AppHeader
           setCookie={setCookie}
           setIsAuth={setIsAuth}
           isAuth={isAuth}
        />
      </Header>
      <Content>
        {
          !isLoadingPage ? 
            (
              !isAuth ?  
              <AppLogin setIsAuth={setIsAuth} setCookie={setCookie} /> :
              <AppHome setIsAuth={setIsAuth} setCookie={setCookie} />
            ) :
            (
              <div className="alotech-loading-container">
                <Spin className="alotech-loading-container-spinner" size="large"  />
              </div>
            )
        }
      </Content>
      <Footer>
        <AppFooter/>
      </Footer>
    </Layout>
  );
}

export default App;
