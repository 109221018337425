import { Tabs } from 'antd';
import AppBigDataReport from '../components/home/big-data-report';
import AppFileUpload from '../components/home/file-upload';

const { TabPane } = Tabs;

function AppHome() {
    return (
        <div className="main block child-tab-bar">
            <Tabs defaultActiveKey="2">
                <TabPane tab="İzinsiz Kayıt Raporu" key="1">
                    <AppBigDataReport />
                </TabPane>
                <TabPane tab="Dosya Yükleme" key="2">
                    <AppFileUpload />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default AppHome;